// ----------------  LAYOUT

.timeline-container {
	max-width: 1024px;
	width: 90%;
	margin: 0 auto;
}

// ----------------  THE FONTS

$border-color: rgba(black, .3);

.timeline-item {
	padding: 3em 2em 2em;
	position: relative;
	color: rgba(black, .7);
	border-left: 2px solid $border-color;

	p {
		font-size: 1rem;
	}

	&::before {
		content: attr(date-is);
		position: absolute;
		left: 2em;
		// font-weight: bold;
		// font-weight: 700;
		top: .75em;
		display: block;
		font-family: 'Roboto', sans-serif;
		font-size: .9rem;
	}

	&::after {
		width: 10px;
		height: 10px;
		display: block;
		top: 1em;
		position: absolute;
		left: -6px;
		border-radius: 10px;
		content: '';
		border: 2px solid $border-color;
		background: white;
	}

	&:last-child {
		border-image: linear-gradient(
			to bottom,
			$border-color 60%,
			rgba($border-color, 0)) 1 100%
		;
	}
}
